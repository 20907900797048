import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Header from '../components/header'
import Footer from '../components/footer'
import Layout from "../components/layout"
import TileMenuItem from '../components/tile-menu-item'
import ConnectModal from '../components/connect-modal'
// import ProjectPreview from '../components/project-preview'

class CommissionPage extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      showConnectModal: false
    }
  }

  handleConnectClicked(e) {
    this.setState({ showConnectModal: true })
    e.preventDefault()
    return false
  }

  handleCloseClicked() {
    this.setState({ showConnectModal: false })
  }

  render() {
    const siteMetadata = get(this, 'props.data.site.siteMetadata')
    const [author] = get(this, 'props.data.allContentfulPerson.edges')
    const { showConnectModal } = this.state

    return (
      <div>
        <Helmet>
          <title>{`COMMISSION | ${siteMetadata.title}`}</title>
          <meta name="description" content={siteMetadata.description} />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          {/*
          <meta property="og:url" content={ogUrl} />
          */}
          <meta property="og:type" content="website" />
          <meta property="og:title" content={siteMetadata.title} />
          <meta property="og:description" content={siteMetadata.description} />
          <meta property="og:image" content={siteMetadata.image} />
        </Helmet>

        <Header data={author.node} />

        <div className="wrapper">
          {/*
            <h2 className="section-headline">Wall</h2>
          */}
          <h3>Commission</h3>
          <ul className="article-list">
            <li>
              <TileMenuItem 
                title="Mural" 
                image="https://res.cloudinary.com/ponnapaweb/image/upload/c_fit,w_480/v1605513573/SHOP/mural_og5rvp.jpg"
                onClick={this.handleConnectClicked.bind(this)}
                linkUrl={'/commission#'}
                 /> 
            </li>
            <li> 
              <TileMenuItem 
                title="Portrait" 
                image="https://res.cloudinary.com/ponnapaweb/image/upload/c_thumb,e_sharpen:100,h_480,w_480,q_80/q_auto,f_auto/v1582425698/PAINT/portrait/In-Memory-of-Brian-Chilcott-2016.jpg" 
                onClick={this.handleConnectClicked.bind(this)}
                linkUrl={'/commission#'} /> 
            </li>
            <li>
              <TileMenuItem 
                title="Sky of the Day" 
                image="https://res.cloudinary.com/ponnapaweb/image/upload/c_thumb,e_sharpen:100,h_400,w_400,q_80/v1587253171/PAINT/sky/Sky-Cover.jpg" 
                onClick={this.handleConnectClicked.bind(this)}
                linkUrl={'/commission#'} />
            </li>
          </ul>
          <br />
          <p className="center">
            Got other ideas? <a href="/commission#" onClick={this.handleConnectClicked.bind(this)}>We would love to hear it!</a>
          </p>
        </div>
        <Footer data={author.node} />
        {showConnectModal &&
          <ConnectModal onClose={() => this.handleCloseClicked()} message="Tell us about your idea for the commission." />
        }
      </div>
    )
  }
}

export default CommissionPage

export const pageQuery = graphql`
  query ComissionPageQuery {
    site {
      siteMetadata {
        title
        siteUrl
        description
        image
      }
    }

    allContentfulPerson(filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }) {
      edges {
        node {
          name
          instagram
          email
        }
      }
    }
  }
`
